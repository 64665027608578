<template>
   <div class="container">
      <div class="row justify-content-md-center">
         <div class="col-12 col-lg-8 account-1">
            <ul>
               <li><strong>Account #:</strong> <span class="account-right">{{$store.state.accountData.account_number}}</span></li>
               <li v-if="$store.state.isWallet"><strong>Wallet ID:</strong> <span class="account-right">{{shortUserName}}</span></li>
               <li v-if="!$store.state.isWallet"><strong>Email:</strong> <span class="account-right">{{$store.state.accountData.username}}</span></li>

               <li v-if="!$store.state.isWallet">
                  <strong>
                     <router-link :to="{name: 'Reset Password'}">
                        Update Password
                     </router-link>
                  </strong>
               </li>


               <div class="container" id="account">
                  <div class="row justify-content-md-center">
                     <div class="col-sm-12 account-1">

                        <ul>
                           <div class="account-slice">
                              <h4 class="text-center mb-4">Credits</h4>
                              <hr>

                              <li v-for="i in [3, 4, 5, 7, 11]" :key="i">
                                 <span class="credit-remaining">{{i}}-Slice Credits: </span>  {{($store.state.accountData && $store.state.accountData['unlimited_subscription_slice_counts'].includes(i)) ? '' : creditsRemaining[i]}} 
                                 
                                 <span class="account-right" v-if="!$store.state.accountData['unlimited_subscription_slice_counts'].includes(i)"><a @click="$event.preventDefault();buy(i)" href="/payment">Buy More</a></span>

                                 <span class="account-right" v-if="$store.state.accountData['unlimited_subscription_slice_counts'].includes(i)">(unlimited)</span>

                                 <p v-if="$store.state.accountData['unlimited_subscription_slice_counts'].includes(i)"><a class="text-danger" @click="$event.preventDefault(); confirmCancelSub = ''; subscriptionToCancel=i; $bvModal.show('cancel-subscription-modal')" href="#">Cancel Subscription</a></p>

                                 <p v-if="isInvoiceStillOpen && openInvoiceCreditNum==i"><a href="payment" >(pending)</a></p>
                              </li>

                           </div>
                        </ul>

                        <div id="credit-history" v-if="creditsUsed.length">
                          <ul>
                            <div class="account-slice" style="margin-bottom:150px">
                              <h4 class="text-center mb-4">Credits Usage History</h4>
                              <p class="text-secondary">The credit shown below reflects the maximum size allowed by that credit. We never track the set size you created.</p>

                                 <li v-for="creditUsed in creditsUsed" :key="creditUsed.id">
                                    <span class="">
                                       <span class="credit-type">{{creditUsed.max_slice_count}}-Slice Credit</span>
                                       <span class="pr-3 text-center">
                                          {{(new Date(creditUsed.activated_date)).toLocaleDateString()}}
                                          <span class="time">{{(new Date(creditUsed.activated_date)).toLocaleTimeString()}}</span>
                                       </span>
                                    </span>
                                 </li>

                              </div>
                           </ul>
                        </div>


                     </div>
                  </div>
               </div>

            </ul>
         </div>
      </div>

      <b-modal class="modal" tabindex="-1" role="dialog" id="cancel-subscription-modal" centered hide-footer>
         <template #modal-title>Confirm Subscription Cancellation</template>
         <p>To cancel your subscription, please type the word CANCEL below. Note: The cancellation will take effect immediately.</p>

         <form class="form mt-3">
            <input type="text" class="form-control mb-2 mr-sm-2" style="text-transform: uppercase" placeholder="CANCEL" v-model="confirmCancelSub">
            <button
               type="submit"
               v-if="confirmCancelSub.toUpperCase()==='CANCEL'"
               @click="$event.preventDefault(); $bvModal.hide('cancel-subscription-modal'); applyCancelSubscription()"
               class="btn btn-lg btn-danger btn-block mb-4 mt-4"
               id="referral-btn"
            >
               Apply
            </button>
            <button
               type="submit"
               v-if="confirmCancelSub.toUpperCase()!=='CANCEL'"
               style="opacity:0.5"
               @click="$event.preventDefault();"
               class="btn btn-lg btn-danger btn-block mb-4 mt-4"
               id="referral-btn"
            >
               Apply
            </button>
            <button
               type="submit"
               v-if="confirmCancelSub!=='CANCEL'"
               style="opacity:0.5"
               @click="$event.preventDefault(); $bvModal.hide('cancel-subscription-modal');"
               class="btn btn-lg btn-secondary btn-block mb-4 mt-4"
               id="referral-btn"
            >
               Don't Change
            </button>
         </form>
      </b-modal>

   </div>
</template>

<script>
export default {
   name: "Account",
   data() {
      return {
         confirmCancelSub: "",
         slicePlan: "",
         creditsRemaining: [],
         creditsUsed: [],
         subscriptionToCancel: 0,
         isInvoiceStillOpen: false,
         openInvoiceCreditNum: 0,
      }
   },
   computed: {
      shortUserName() {
         let result = this.$store.state.accountData.username;
         if (result.length > 8) {
            result = result.substring(0, 5) + "..." + result.substring(result.length - 5);
         }
         return result;
      }
   },
   mounted() {
      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.creditsRemaining = response.data.credits_remaining;
            this.creditsUsed = response.data.credits_used.filter(
               function(creditUsed) { 
                  return creditUsed.retries_available <= creditUsed.retries_so_far;
               }
            );

            this.$store.commit("setAccountData", response.data);
         })
         .catch((error) => console.error(error));
      if (this.$store.state.activeInvoiceId) {
         this.axios
            .post("/api/check-payment-status", {invoiceId: this.$store.state.activeInvoiceId}, { withCredentials: true })
            .then((response) => {
               this.isInvoiceStillOpen = !(response.data.is_settled || response.data.is_discarded);
               this.openInvoiceCreditNum = response.data.product;
            })
            .catch((error) => {
               console.error(error);
            })
      }
   },
   methods: {
      buy(sliceCount) {
         window.location.assign(`/payment?sliceCount=${sliceCount}`);
      },
      validate(creditUsedId) {
         window.location.assign(`/validateset?credit=${creditUsedId}&c=1`);
      },
      applyCancelSubscription() {
         this.axios
            .post("/api/cancel-subscription", {"slices": this.subscriptionToCancel}, { withCredentials: true })
            .then((response) => {
               console.log(response.data);
               window.location.assign("account"); // force refresh
            })
            .catch((error) => console.error(error));
      }
   }
}
</script>

